import ProfileCircle from "@/components/icons/ProfileCircle";
import CartDropDown from "./cart-drop-down/CartDropDown";
import useHandleOutSide from "@/hooks/useHandleOutSide";
import BurgerMenu from "@/components/icons/BurgerMenu";
import SearchIcon from "@/components/icons/SearchIcon";
import AuthModal from "@/components/modals/AuthModal";
import SideMenu from "@/components/modals/SideMenu";
import CartIcon from "@/components/icons/CartIcon";
import LogoEn from "@/components/icons/LogoEn";
import LogoAr from "@/components/icons/LogoAr";
import Heart from "@/components/icons/Heart";
import UserDropDown from "./UserDropDown";
import Search from "./Search";
import Link from "next/link";

import { useCart } from "@/context/cart";
import { useRef, useState } from "react";
import { useSession } from "next-auth/react";
import { useWishlist } from "@/context/wishlist";
import { calcCartTotalHandler } from "@/helpers/cart";
import useClient from "@/hooks/global/useClient";
import CurrencyIcon from "@/components/icons/CurrencyIcon";

const Header = ({
  t,
  locale,
  push,
  setInputValue,
  inputValue,
  ourSearchInput,
  auth,
  setAuth,
}) => {
  const cart = useCart();
  const refCart = useRef();
  const refUser = useRef();
  const { wishlist } = useWishlist();
  const { data: session } = useSession();
  const [active, setActive] = useState(false);
  const [sideModal, setSideModal] = useState(false);
  const [cartDropDown, setCartDropDown] = useState(false);
  const [userDropDown, setUserDropDown] = useState(false);

  useHandleOutSide(refCart, () => setCartDropDown(false));
  useHandleOutSide(refUser, () => setUserDropDown(false));

  const cartTotal = calcCartTotalHandler(cart?.cart?.cartItems || []);
  const { isClient } = useClient();

  return (
    <>
      <header className="w-11/12 mx-auto">
        <div
          className={`flex flex-wrap items-center justify-between gap-4 mx-auto sm:pt-3 sm:py-5 lg:gap-20 max-sm:pt-1 max-sm:pb-3 ${
            !active && "-mb-2"
          } `}
        >
          <Link href="/">
            {locale === "ar" ? (
              <LogoAr className="max-sm:w-36" />
            ) : (
              <LogoEn className="max-sm:w-36" />
            )}
          </Link>
          <Search
            {...{
              t,
              locale,
              active,
              setInputValue,
              inputValue,
              ourSearchInput,
            }}
          />
          <div className="items-center hidden lg:flex gap-9">
            <div className="relative flex items-center gap-3 group">
              <ProfileCircle />
              <div className="flex flex-col">
                <button
                  onClick={() => {
                    if (session?.user?.id) {
                      setUserDropDown(!userDropDown);
                      setCartDropDown(false);
                    } else {
                      setAuth("register");
                    }
                  }}
                  className="text-[15px] text-zinc-500 text-start font-light"
                >
                  {session?.user?.id ? t("welcome") : t("Register")}
                </button>
                <button
                  onClick={() => {
                    if (session?.user?.id) {
                      setUserDropDown(!userDropDown);
                      setCartDropDown(false);
                    } else {
                      setAuth("login");
                    }
                  }}
                  className="text-[15px] font-extrabold text-start"
                >
                  {session?.user?.id ? session?.user?.firstname : t("Login")}
                </button>
                {session?.user?.telesales && (
                  <span className="hidden group-hover:block absolute -bottom-7 z-[9999] left-1/2 -translate-x-1/2 bg-black text-[13px] font-normal px-3 py-0.5 rounded-md text-white">
                    Telesales
                  </span>
                )}
              </div>
              {userDropDown && (
                <UserDropDown
                  locale={locale}
                  refUser={refUser}
                  setUserDropDown={setUserDropDown}
                  push={push}
                />
              )}
            </div>
            <Link href="/user/favourites" className="relative">
              <Heart />
              {isClient && Object.keys(wishlist || {})?.length > 0 && (
                <span className="absolute z-40 grid text-sm text-white bg-red-600 rounded-full -right-2 -top-2 size-5 place-items-center">
                  {Object.keys(wishlist || {})?.length}
                </span>
              )}
            </Link>
            <div className="relative">
              <div
                className="flex items-center gap-3 cursor-pointer"
                onClick={() => {
                  setCartDropDown(!cartDropDown);
                  setUserDropDown(false);
                }}
              >
                {isClient && (
                  <>
                    <div className="relative block">
                      <CartIcon />
                      {isClient && cart?.cart?.cartItems?.length > 0 && (
                        <span className="absolute z-40 grid text-sm text-white bg-red-600 rounded-full -right-2 -top-2 size-5 place-items-center">
                          {cart?.cart?.cartItems?.length}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col">
                      <span className="text-[15px] text-zinc-500 font-light">
                        {t("Total")}
                      </span>
                      <span className="text-[15px] flex items-center gap-1 font-extrabold">
                        {isClient && cartTotal ? cartTotal.toFixed(2) : 0}{" "}
                        <CurrencyIcon className="size-3.5" />
                      </span>
                    </div>
                  </>
                )}
              </div>

              {cartDropDown && (
                <CartDropDown
                  onHide={() => setCartDropDown(false)}
                  ref={refCart}
                  items={cart?.cart?.cartItems}
                  cartTotal={isClient && cartTotal ? cartTotal.toFixed(2) : 0}
                />
              )}
            </div>
          </div>
          <div className="flex items-center gap-3 sm:gap-5 lg:hidden">
            <SearchIcon
              onClick={() => setActive(!active)}
              className="size-6 sm:size-7"
            />
            <BurgerMenu
              onClick={() => setSideModal(!sideModal)}
              className="size-6 sm:size-7"
            />
          </div>
        </div>
      </header>
      <AuthModal {...{ t, auth, setAuth, locale }} />
      <SideMenu {...{ t, locale, sideModal, setSideModal, setAuth, push }} />
    </>
  );
};

export default Header;
