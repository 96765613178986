import React, { useState } from "react";
import Image from "@/components/global/Image";
import { priceFormatedObject, validatePrice } from "@/helpers/product";
import { getCookies, setCookie } from "cookies-next";
import { deleteCartItem, updateCartItem } from "@/services/cart";
import { useRouter } from "next/router";
import toast from "react-hot-toast";
import { useCart } from "@/context/cart";
import AddIcon from "../../icons/AddIcon";
import Remove from "../../icons/Remove";
import MinusIcon from "../../icons/MinusIcon";
import { handlePriceByComma } from "@/helpers/common/handlePrice";
import { useTranslation } from "next-i18next";
import { event_toggle_cart } from "@/utils/gtm";
import Link from "next/link";
import CurrencyIcon from "@/components/icons/CurrencyIcon";

const CartModalItem = ({ cartItem }) => {
  const [loadingBtn, setLoadingBtn] = useState("");
  const { handshake, mage, quote } = getCookies();
  const { locale } = useRouter();
  const { dispatch: dispatchCart } = useCart();
  const { t } = useTranslation("common");

  const price = validatePrice(priceFormatedObject(cartItem));

  const handleDeleteCartItem = async (item_id) => {
    setLoadingBtn("deleting");
    const res = await deleteCartItem(handshake, mage, quote, item_id);
    if (res?.status === 404) {
      dispatchCart({
        type: "remove",
        payload: { item_id },
      });
    }

    if (res?.data?.quoteId !== undefined && res?.data?.quoteId != quote) {
      setCookie("quote", res?.data?.quoteId);
      dispatchCart({ type: "clear" });
    }

    if (res?.status === 200) {
      toast.success(
        locale === "en" ? "Item Deleted From Cart!" : "تم مسح المنتج من العربة"
      );
      dispatchCart({
        type: "remove",
        payload: { item_id },
      });
      setLoadingBtn("");
      event_toggle_cart(cartItem, "remove_from_cart", 1);
    } else {
      toast.error(
        locale === "en"
          ? "Error Occured, please try again"
          : "حذث خطأ, برجاء المحاولة مره اخرى"
      );
      setLoadingBtn("");
    }
  };

  const handleUpdateItem = async (qty, isMinus = false, item_id) => {
    !isMinus ? setLoadingBtn("adding") : setLoadingBtn("subtracting");

    const res = await updateCartItem(handshake, mage, quote, item_id, qty);
    if (res?.status === 404) {
      dispatchCart({
        type: "remove",
        payload: { item_id },
      });
    }

    if (res?.data?.quoteId !== undefined && res?.data?.quoteId != quote) {
      setCookie("quote", res?.data?.quoteId);
      dispatchCart({ type: "clear" });
    }

    if (res?.status === 200) {
      toast.success(
        locale === "en"
          ? "Item Updated Successfully"
          : "تم تعديل كمية المنتج بنجاح"
      );
      dispatchCart({
        type: "update",
        payload: { item_id, qty },
      });
      setLoadingBtn("");
      if (!isMinus) {
        event_toggle_cart(cartItem, "add_to_cart", qty);
      } else {
        event_toggle_cart(cartItem, "remove_from_cart", qty);
      }
    } else {
      toast.error(
        res?.error?.error?.message
          ? t(res?.error?.error?.message)
          : locale === "en"
          ? "Error Occured, please try again"
          : "حذث خطأ, برجاء المحاولة مره اخرى"
      );
      if (isMinus) {
        await handleDeleteCartItem(
          cartItem?.item_id || cartItem?.data?.item_id
        );
      }
      setLoadingBtn("");
    }
  };

  return (
    <div className="flex gap-3 p-2 my-3 border rounded-sm">
      <Image
        loading="lazy"
        src={
          cartItem?._media_?.image?.[0]?.image ||
          `${process.env.NEXT_PUBLIC_IMG_BASEURL}/catalog/product${
            cartItem?.image?.at(0) ||
            cartItem?.extension_attributes?.product_image
          }`
        }
        className="object-contain border min-h-24 min-w-24 max-h-24 max-w-24"
        alt="Cart Modal Img!"
      />
      <div className="flex flex-col w-full gap-2 pr-2 max-sm:text-sm">
        <Link
          href={`/product/${
            cartItem?.extension_attributes?.parent_rewrite_url ||
            cartItem?.extension_attributes?.rewrite_url ||
            cartItem?.rewrite_url ||
            ""
          }`}
          className="font-bold line-clamp-2"
        >
          {cartItem?.name}
        </Link>
        <p className="text-sm font-semibold text-zinc-500">
          {cartItem?.category?.at(1)?.name ||
            cartItem?.extension_attributes?.category_names[0]?.name ||
            ""}
        </p>
        <div className="flex flex-wrap items-center justify-between gap-2">
          <div className="flex items-center gap-3">
            <p className="flex items-center gap-1 font-bold text-red-600">
              {handlePriceByComma(price?.specialPrice || price?.originalPrice)}{" "}
              <CurrencyIcon className="size-3.5" />
            </p>
            {price?.specialPrice && (
              <p className="flex items-center gap-1 font-semibold line-through text-zinc-500">
                {handlePriceByComma(price?.originalPrice)}{" "}
                <CurrencyIcon className="size-3.5" />
              </p>
            )}
          </div>
          <div className="flex items-center">
            <button
              onClick={() =>
                handleUpdateItem(
                  cartItem?.qty ? cartItem?.qty + 1 : 2,
                  false,
                  cartItem?.item_id || cartItem?.data?.item_id
                )
              }
              className="flex items-center justify-center border rounded-md size-8 disabled:cursor-not-allowed"
              disabled={loadingBtn === "adding"}
            >
              {loadingBtn === "adding" ? (
                <span className="border-t border-r border-black rounded-full animate-spin size-4" />
              ) : (
                <AddIcon className="size-3" />
              )}
            </button>
            <span className="flex items-center justify-center w-12 h-8">
              {cartItem?.qty || 1}
            </span>
            <button
              onClick={
                cartItem?.qty === 1
                  ? () =>
                      handleDeleteCartItem(
                        cartItem?.item_id || cartItem?.data?.item_id
                      )
                  : () =>
                      handleUpdateItem(
                        cartItem?.qty - 1,
                        true,
                        cartItem?.item_id || cartItem?.data?.item_id
                      )
              }
              className="flex items-center justify-center border rounded-md size-8"
              disabled={
                loadingBtn === "deleting" || loadingBtn === "subtracting"
              }
            >
              {cartItem?.qty === 1 ? (
                loadingBtn === "deleting" ? (
                  <span className="border-t border-r border-black rounded-full animate-spin size-4" />
                ) : (
                  <Remove />
                )
              ) : cartItem?.qty !== 1 ? (
                loadingBtn === "subtracting" ? (
                  <span className="border-t border-r border-black rounded-full animate-spin size-4" />
                ) : (
                  <MinusIcon className="size-3" />
                )
              ) : (
                ""
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartModalItem;
