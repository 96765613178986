import { handlePriceByComma } from "@/helpers/common/handlePrice";
import { validatePrice } from "@/helpers/product";
import { useRouter } from "next/router";
import CurrencyIcon from "../icons/CurrencyIcon";

const Price = ({ prices, price: price_, productType, fromModal }) => {
  const { locale } = useRouter();

  const price = validatePrice(prices, price_, productType);
  return (
    <div className="flex items-center justify-between gap-4">
      <div className="flex items-center gap-2 sm:gap-4">
        <p
          className={`max-sm:text-lg flex items-center gap-1 font-extrabold text-red-600 md:text-3xl ${
            fromModal ? "max-sm:text-[13px]" : ""
          }`}
        >
          {handlePriceByComma(price?.specialPrice || price?.originalPrice)}{" "}
          <CurrencyIcon className="size-3.5" />
        </p>
        {price?.specialPrice && (
          <p
            className={`text-sm flex items-center gap-1 sm:text-lg font-normal line-through text-zinc-400 ${
              fromModal ? "max-sm:text-[11px]" : ""
            }`}
          >
            {handlePriceByComma(price?.originalPrice)}{" "}
            <CurrencyIcon className="size-3.5" />
          </p>
        )}
      </div>
      {price?.specialPrice && (
        <span
          className={`px-2 max-sm:text-xs sm:px-4 py-2 text-sm font-semibold text-center text-white bg-red-600 rounded-md ${
            fromModal ? "text-xs" : ""
          }`}
        >
          {/* {`${locale === "en" ? "Save" : "وفر"} 
          ${handlePriceByComma(
            price?.specialPrice - price?.originalPrice
          )} ${locale === "en" ? "SAR" : "ر.س"}`} */}
          {locale === "en" ? "Save" : "وفر"}
          {` `}
          {Number(
            ((price?.originalPrice - price?.specialPrice) /
              price?.originalPrice) *
              100
          ).toFixed(0)}
          {"%"}
        </span>
      )}
    </div>
  );
};

export default Price;
