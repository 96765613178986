import { useRouter } from "next/router";
import { useState } from "react";

import Link from "next/link";
import Eye from "../icons/Eye";
import CartIcon from "../icons/CartIcon";
import Image from "@/components/global/Image";
import ProductQuickView from "../modals/QuickView";
import StarsRateIcon from "../icons/StarsRateIcon";
import AddToCart from "../global/buttons/AddToCart";
import AddtoWishlist from "../global/buttons/AddtoWishlist";
import CloseIcon from "../icons/CloseIcon";
import { validatePrice } from "@/helpers/product";
import { handlePriceByComma } from "@/helpers/common/handlePrice";
import { summarizeOptions } from "@/utils/global";
import { useTranslation } from "next-i18next";
import { add_event_name } from "@/utils/gtm";
import CurrencyIcon from "../icons/CurrencyIcon";

const ProductCard = ({ product, onRemoveCompare, productQuickViewRef }) => {
  const { locale, push } = useRouter();
  const { t } = useTranslation("common");
  const [showQuickReview, setShowQuickReview] = useState(false);
  const [image, setImage] = useState(product?._media_?.image?.at(0)?.image);
  const price = validatePrice(
    product?.prices_with_tax,
    product?.price?.at(0),
    product?.type_id
  );
  const summary = summarizeOptions(product?._configurables);
  return (
    <>
      <div className="relative mx-px border rounded-sm cursor-pointer group sm:h-[460px] sm:min-h-[460px] sm:max-h-[460px] flex flex-col justify-between">
        <div
          onMouseEnter={() =>
            product?._media_?.image?.at(1)?.image &&
            setImage(product?._media_?.image?.at(1)?.image)
          }
          onMouseLeave={() => setImage(product?._media_?.image?.at(0)?.image)}
          className="relative"
        >
          {onRemoveCompare && (
            <div
              onClick={onRemoveCompare}
              className="absolute -top-6 -right-3 bg-red border rounded-md p-2 border-[#CDCDCD]"
            >
              <CloseIcon
                stroke="black"
                strokeWidth={2}
                width={14}
                height={14}
              />
            </div>
          )}

          {product?._media_?.image?.at(0)?.image ? (
            <Image
              loading="eager"
              src={image}
              onClick={() => {
                push(`/product/${product?.rewrite_url}`);
                add_event_name(product, "select_item");
              }}
              className="object-contain w-full cursor-pointer aspect-square"
              alt=""
            />
          ) : null}
          <div className="absolute z-50 flex-col items-center gap-3 max-sm:right-2 sm:text-red-600 h-fit sm:flex-row top-2 sm:top-auto sm:bottom-0 sm:hidden sm:-translate-x-1/2 sm:group-hover:flex sm:left-1/2">
            <AddtoWishlist product={product} />
            <button
              onClick={() => setShowQuickReview(true)}
              className="hidden sm:flex items-center justify-center bg-white mt-1.5 border border-red-600 rounded-md size-8 sm:mt-0"
            >
              <Eye className="size-4" />
            </button>
          </div>
        </div>
        {price.specialPrice ? (
          <span className="absolute z-50 px-3 py-1 text-xs text-white bg-red-600 rounded-md top-2.5 start-3">
            {locale === "en" ? "Save" : "وفر"}{" "}
            {Number(
              ((price?.originalPrice - price?.specialPrice) /
                price?.originalPrice) *
                100
            ).toFixed(0)}
            %
          </span>
        ) : null}
        <div className="flex flex-col w-full p-1.5 sm:p-3 *:mb-1">
          <Link
            href={`/product/${product?.rewrite_url || ""}`}
            onClick={() => add_event_name(product, "select_item")}
            className="text-xs font-semibold sm:text-lg line-clamp-1"
          >
            {product?.name || ""}
          </Link>
          <p
            className={`text-[8px] sm:text-sm line-clamp-1 text-[#B2B2B2] font-[400] ${
              !product?.category?.at(1)?.name && "invisible"
            }`}
          >
            {product?.category?.at(1)?.name || "unknown"}
          </p>
          <div className="flex items-center gap-1 sm:gap-2">
            <StarsRateIcon width={16} height={16} rate={5} />
            <span className="text-sm">({product?.rewiews_count || 4.7})</span>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col">
              <span className="flex items-center gap-1 font-bold text-red-600 sm:text-lg whitespace-nowrap">
                {handlePriceByComma(
                  price?.specialPrice || price?.originalPrice
                )}{" "}
                <CurrencyIcon className="size-3.5" />
              </span>
              <span
                className={`max-sm:text-[8px] flex items-center gap-1 text-sm line-through whitespace-nowrap text-zinc-400 
                ${price.specialPrice ? "visible" : "invisible"}
                `}
              >
                {handlePriceByComma(price?.originalPrice)}{" "}
                <CurrencyIcon className="size-3.5" />
              </span>
            </div>
            {product?.stock?.is_in_stock ? (
              product?._configurables?.length ? (
                <button
                  onClick={() => setShowQuickReview(true)}
                  className="flex items-center justify-center text-white bg-red-600 rounded-full disabled:cursor-not-allowed sm:opacity-0 sm:group-hover:opacity-100 size-[30px] sm:size-9"
                >
                  <CartIcon className="size-4 sm:size-5" />
                </button>
              ) : (
                <AddToCart {...{ product, configurations: null }} />
              )
            ) : (
              <button className="flex items-center justify-center text-white bg-red-600 bg-opacity-50 rounded-full cursor-not-allowed sm:opacity-0 sm:group-hover:opacity-100 size-[30px] sm:size-9">
                <CartIcon className="size-4 sm:size-5" />
              </button>
            )}
          </div>
          <div
            className={`min-h-8 ${
              Object.values(summary)?.length > 0 ? "visible" : "invisible"
            }`}
          >
            {Object.entries(summary)
              ?.slice(0, 2)
              .map((property) =>
                property?.at(0) == "size" ? (
                  <div
                    key={property?.at(0)}
                    className="flex gap-2 px-2 py-1 text-sm text-gray-600 bg-gray-100 border border-gray-200 rounded-md "
                  >
                    <p>{t(property?.at(0) + "s")} :</p>
                    <p>{property?.at(1)?.size}</p>
                  </div>
                ) : (
                  <div
                    key={property?.at(0)}
                    className="flex items-center gap-2 overflow-hidden sm:overflow-x-auto"
                  >
                    <p className="whitespace-nowrap">
                      {t(property?.at(0) + "s")} :
                    </p>
                    <div className="flex gap-2 max-w-[112px] overflow-hidden">
                      {[...property?.at(1)]?.map((color, index) => {
                        return (
                          <Image
                            key={color}
                            loading="lazy"
                            src={
                              product?._configurables?.[index]?._media_
                                ?.image?.[0]?.image
                            }
                            className="border rounded-md size-8"
                            alt="Img!"
                          />
                        );
                      })}
                    </div>
                  </div>
                )
              )}
          </div>
        </div>
      </div>
      {showQuickReview && (
        <ProductQuickView
          onHide={() => setShowQuickReview(false)}
          show={showQuickReview}
          product={product}
          productQuickViewRef={productQuickViewRef}
        />
      )}
    </>
  );
};

export default ProductCard;
