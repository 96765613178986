import Image from "../global/Image";

const ColorSizes = ({
  language,
  handleOnClickConfigurable,
  selectedIdx,
  configurables,
  fromModal,
}) => {
  return (
    <>
      {Object.entries(configurables || {})?.map((item, key) => (
        <div key={key} className="flex flex-col gap-3">
          <div className="flex items-center justify-between">
            {Object.values(item[1])?.length > 0 && (
              <h2
                className={`sm:text-lg font-extrabold md:text-xl ${
                  fromModal ? "max-sm:text-[13px] max-sm:font-[400]" : ""
                }`}
              >
                {item[0] === "color" ? (
                  <div className="flex gap-1 font-bold text-black max-sm:text-[13px]">
                    {language === "en" ? "Color" : "اللون"}
                    <span className="font-normal">
                      (
                      {
                        Object.values(item[1])[selectedIdx?.[item[0]]]?.[0]
                          ?.label
                      }
                      )
                    </span>
                  </div>
                ) : item[0] === "size" ? (
                  <div className="flex gap-1 font-bold text-black max-sm:text-[13px]">
                    {language === "en" ? "Size" : "المقاس"}
                    <span className="font-normal">
                      (
                      {
                        Object.values(item[1])[selectedIdx?.[item[0]]]?.[0]
                          ?.label
                      }
                      )
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </h2>
            )}
          </div>

          <div className="flex flex-wrap w-full gap-3 px-0">
            {Object.values(item[1])?.map((info, i) => (
              <>
                {item[0] == "size" ? (
                  <button
                    // disabled={info?.quantity === 0}
                    className={`flex flex-col items-center justify-center p-2 border ${
                      i === selectedIdx?.[item[0]] ? "border-red-500" : ""
                    }  rounded-sm disabled:cursor-not-allowed disabled:opacity-50`}
                    onClick={() => {
                      handleOnClickConfigurable(info?.[0], i, item[0]);
                    }}
                  >
                    <span
                      className={`block font-bold text-zinc-500 ${
                        fromModal ? "max-sm:text-[13px]" : ""
                      }`}
                      dir="ltr"
                    >
                      {info?.[0]?.label || "--"}
                    </span>
                  </button>
                ) : item[0] == "color" ? (
                  <button
                    // disabled={info?.quantity === 0}
                    className={`flex flex-col items-center justify-center p-2 border ${
                      i === selectedIdx?.[item[0]] ? "border-red-500" : ""
                    } rounded-sm disabled:cursor-not-allowed disabled:opacity-50`}
                    onClick={() =>
                      handleOnClickConfigurable(info?.[0], i, item[0])
                    }
                  >
                    <Image
                      src={info?.[0]?._media_.image?.[0]?.image}
                      loading="eager"
                      className="object-contain min-h-[45px]"
                      height={45}
                      width={45}
                      alt="Color Img!"
                    />
                    {/* <span
                    className={`sm:text-lg font-semibold sm:font-extrabold text-red-600 ${
                      fromModal ? "max-sm:text-[13px]" : ""
                    }`}
                  >
                    {info?.prices_with_tax?.price || 0}{" "}
                    {language === "en" ? "SAR" : "ر.س"}
                  </span> */}
                  </button>
                ) : (
                  <button
                    // disabled={info?.quantity === 0}
                    className={`flex flex-col items-center justify-center w-full p-2 border ${
                      i === selectedIdx?.size ? "border-red-500" : ""
                    } rounded-sm disabled:cursor-not-allowed disabled:opacity-50`}
                    onClick={() =>
                      handleOnClickConfigurable(info?.[0], i, item[0])
                    }
                  >
                    <span
                      className={`block font-bold text-zinc-500 ${
                        fromModal ? "max-sm:text-[13px]" : ""
                      }`}
                      dir="ltr"
                    >
                      {info?.[0]?.label || "--"}
                    </span>
                  </button>
                )}
              </>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default ColorSizes;
